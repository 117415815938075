import React, { useState, FC, useEffect } from "react";
import Table, { TableProps } from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import ContentLayout from "@cloudscape-design/components/content-layout";
import { useCollection } from "@cloudscape-design/collection-hooks";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import { useNavigate } from "react-router-dom";
import { ToastContainer, ToastOptions, toast } from "react-toastify";

import {
  deleteItemFromDynamo,
  scanDynamoDBWithPagination,
  updateItemInDynamo,
} from "../services/ddb";
import { BabyActionInterface } from "../interfaces";
import { useLocalStorage } from "../hooks/use-local-storage";
import {
  Container,
  FormField,
  Input,
  Link,
  Modal,
  Grid,
} from "@cloudscape-design/components";
import { BabyCareAction } from "../constants";
import { formatDateString } from "../utils";
import { BabyOverviewWidget } from "./common/OverviewStats";
import { BaseStaticWidget } from "./dashboard/base-static-widget";

interface ListProps {
  user: string;
}

const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PREFERENCES = {
  pageSize: DEFAULT_PAGE_SIZE,
  contentDisplay: [
    { id: "time", visible: true },
    { id: "action", visible: true },
  ],
  wraplines: false,
  stripedRows: false,
  contentDensity: "comfortable",
};

export function createTableSortLabelFn(
  column: TableProps.ColumnDefinition<unknown>
): TableProps.ColumnDefinition<unknown>["ariaLabel"] {
  if (!column.sortingField && !column.sortingComparator && !column.ariaLabel) {
    return;
  }
  return ({ sorted, descending }) => {
    return `${column.header}, ${
      sorted
        ? `sorted ${descending ? "descending" : "ascending"}`
        : "not sorted"
    }.`;
  };
}

export const FILTERING_PROPERTIES = [
  {
    propertyLabel: "Action",
    key: "action",
    groupValuesLabel: "Action values",
    operators: [":", "!:", "=", "!=", "^", "!^"],
  },
];

const toastProps: ToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

interface ModalDetailsProps {
  item: BabyActionInterface;
}

export const ModalDetails: FC<ModalDetailsProps> = (props) => {
  const [visibile, setVisible] = useState<boolean>(false);
  const [maternalQuantity, setMaternalQuantity] = useState<number>(
    props.item.maternalMilkQuantity ?? 0
  );
  const [artificialQuantity, setArtificialQuantity] = useState<number>(
    props.item.artificialMilkQuantity ?? 0
  );

  const handleUpdateItem = () => {
    const key = {
      id: props.item.id,
      time: props.item.time,
    };

    const updateData = {
      maternalMilkQuantity: maternalQuantity,
      artificialMilkQuantity: artificialQuantity,
    };

    updateItemInDynamo(key, updateData)
      .then(() => {
        toast.success("🎉 Successfully updated item", toastProps);
        setVisible(false);
      })
      .catch((error: any) => {
        toast.error(
          `Failed to update item, rason: ${error.message}`,
          toastProps
        );
      });
  };

  return (
    <>
      <Link onClick={() => setVisible(true)}>
        {formatDateString(props.item.time) ?? props.item.time}
      </Link>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visibile}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => setVisible(false)}>Close</Button>
              <Button
                variant="primary"
                onClick={() => {
                  handleUpdateItem();
                }}
                disabled={props.item.action !== BabyCareAction.Feeding}
              >
                Update
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="View action details"
      >
        <Container>
          {" "}
          <FormField description="Time">
            <Input value={props.item.time} disabled={true} />
          </FormField>
          <FormField description="Action performed">
            <Input value={props.item.action} disabled={true} />
          </FormField>
          {props.item.action === BabyCareAction.Feeding && (
            <>
              <FormField description="🍼 Artificial milk quantity">
                <Input
                  value={artificialQuantity!.toString()}
                  onChange={({ detail }) =>
                    setArtificialQuantity(Number(detail.value))
                  }
                  type="number"
                />
              </FormField>
              <FormField description="🤱 Maternal milk quantity">
                <Input
                  value={maternalQuantity!.toString()}
                  type="number"
                  onChange={({ detail }) =>
                    setMaternalQuantity(Number(detail.value))
                  }
                />
              </FormField>
            </>
          )}
        </Container>
      </Modal>
    </>
  );
};

export const List: FC<ListProps> = (props) => {
  const navigate = useNavigate();
  const getDdbItems = async () => {
    setLoading(true);
    scanDynamoDBWithPagination().then((items) => {
      setBabyActions(items as BabyActionInterface[]);
      setLoading(false);
    });
    setLoading(false);
  };

  const handleCreate = () => {
    navigate("/create");
  };

  const handleDelete = () => {
    const itemToDelete: BabyActionInterface = selectedItems[0];
    alert(`
Are you sure you want to delete the following action:

- Date: ${itemToDelete.time}
- Action: ${itemToDelete.action}
    
Are you sure about that ? are you really sure about that ?
    `);

    try {
      deleteItemFromDynamo({
        id: itemToDelete.id,
        time: itemToDelete.time,
      });
      toast.success("🎉 🎊 Successfully deleted item", toastProps);
    } catch (error) {
      toast.error("😭 Failed to deleted item", toastProps);
    }

    getDdbItems();
  };

  const [babyActions, setBabyActions] = useState<BabyActionInterface[]>([]);
  const [selectedItems, setSelectedItems] = useState<BabyActionInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [preferences, setPreferences] = useLocalStorage<any>(
    "React-TableDateFilter-Preferences",
    DEFAULT_PREFERENCES
  );
  const [filteringProperties, setFilteringProperties] = useLocalStorage(
    "React-TableDateFilter-FilteringPreferences",
    FILTERING_PROPERTIES
  );

  const rawColumns = [
    {
      id: "id",
      header: "id",
      cell: (item: any) => item.id,
      sortingField: "id",
      isRowHeader: false,
    },
    {
      id: "time",
      header: "Time",
      cell: (item: BabyActionInterface) => (
        <>
          <ModalDetails item={item} />
        </>
      ),
      sortingField: "time",
      isRowHeader: true,
    },
    {
      id: "action",
      header: "Action performed",
      cell: (item: any) => item.action,
      sortingField: "action",
      isRowHeader: true,
    },
    {
      id: "parent",
      header: "Parent",
      cell: (item: any) => item.parent,
      sortingField: "alt",
    },
  ];

  const COLUMN_DEFINITIONS = rawColumns.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(babyActions, {
    propertyFiltering: {
      filteringProperties,
      empty: (
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button>Create resource</Button>
          </SpaceBetween>
        </Box>
      ),
      noMatch: (
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No match</b>
          </SpaceBetween>
        </Box>
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: COLUMN_DEFINITIONS[0] } },
    selection: {},
  });

  useEffect(() => {
    getDdbItems();
  }, []);

  const header = (
    <Header
      variant="h3"
      actions={
        <SpaceBetween size="xs" direction="horizontal">
          <Button
            data-testid="header-btn-delete"
            variant="normal"
            disabled={selectedItems.length === 0}
            onClick={handleDelete}
          >
            Remove
          </Button>
          <Button
            data-testid="header-btn-create"
            variant="primary"
            iconName="add-plus"
            onClick={handleCreate}
          >
            Create
          </Button>
        </SpaceBetween>
      }
    >
      Baby Care Progress Table
    </Header>
  );
  return (
    <>
      <ContentLayout
        header={
          <Header variant="h2">Hello {`${props.user.split(" ")[0]}`} 👋</Header>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          {[BabyOverviewWidget].map((widget, index) => (
            <BaseStaticWidget key={index} config={widget.data} />
          ))}
          <Table
            {...collectionProps}
            renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
              `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            onSelectionChange={({ detail }) =>
              // @ts-ignore
              setSelectedItems(detail.selectedItems)
            }
            selectedItems={selectedItems}
            ariaLabels={{
              selectionGroupLabel: "Items selection",
              allItemsSelectionLabel: ({ selectedItems }) =>
                `${selectedItems.length} ${
                  selectedItems.length === 1 ? "item" : "items"
                } selected`,
              itemSelectionLabel: ({ selectedItems }, item) => item.id,
            }}
            columnDefinitions={COLUMN_DEFINITIONS}
            columnDisplay={preferences.contentDisplay}
            resizableColumns={true}
            enableKeyboardNavigation
            items={items}
            loading={loading}
            loadingText="Loading resources"
            selectionType="single"
            trackBy="id"
            header={header}
            pagination={<Pagination {...paginationProps} />}
            preferences={
              <CollectionPreferences
                preferences={preferences}
                onConfirm={({ detail }) => setPreferences(detail)}
                pageSizePreference={{
                  options: [
                    { value: 10, label: "10 actions" },
                    { value: 30, label: "30 actions" },
                    { value: 50, label: "50 actions" },
                  ],
                }}
              />
            }
          />
        </SpaceBetween>
      </ContentLayout>
      <ToastContainer />
    </>
  );
};
