// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useEffect, useState } from "react";
import {
  BarChart,
  BarChartProps,
  Box,
  Header,
  Link,
} from "@cloudscape-design/components";
import { BabyActionInterface, WidgetConfig } from "../../interfaces";
import { scanDynamoDBWithPagination } from "../../services/ddb";
import { transformDataForFoodCount } from "../../utils";

const barChartInstructions =
  "Use left/right arrow keys to navigate between data groups.";

const dateFormatter = (date: Date) =>
  date
    .toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour12: false,
    })
    .split(" ")
    .join("\n");

const numberTickFormatter = (value: number) => {
  if (Math.abs(value) < 1000) {
    return value.toString();
  }
  return (value / 1000).toFixed() + "k";
};

const commonChartProps = {
  loadingText: "Loading chart",
  errorText: "Error loading data.",
  recoveryText: "Retry",
  empty: (
    <Box textAlign="center" color="inherit">
      <b>No data available</b>
      <Box variant="p" color="inherit">
        There is no data available
      </Box>
    </Box>
  ),
  noMatch: (
    <Box textAlign="center" color="inherit">
      <b>No matching data</b>
      <Box variant="p" color="inherit">
        There is no matching data to display
      </Box>
    </Box>
  ),
  i18nStrings: {
    filterLabel: "Filter displayed data",
    filterPlaceholder: "Filter data",
    filterSelectedAriaLabel: "selected",
    legendAriaLabel: "Legend",
    chartAriaRoleDescription: "line chart",
    xAxisAriaRoleDescription: "x axis",
    yAxisAriaRoleDescription: "y axis",
    yTickFormatter: numberTickFormatter,
  },
};

export const FoodCountWidget: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    title: "🍼 ∑ Baby food count",
    description: "Number of time baby was given food",
    header: FoodHoursHeader,
    content: FoodHoursContent,
    staticMinHeight: 560,
  },
};

function FoodHoursHeader() {
  return (
    <Header variant="h2" description="Number of time baby was given food">
      🍼 ∑ Lily's food count
    </Header>
  );
}

const getFoodData = (data: BabyActionInterface[]) => {
  const babyWeightData = transformDataForFoodCount(data);

  const foodSeries: BarChartProps<Date>["series"] = [
    {
      title: "Sum",
      type: "bar",
      data: babyWeightData.map((datum) => ({
        x: datum.date,
        y: datum["feedingCount"] ?? 0,
      })),
    },
    {
      title: "Max",
      type: "threshold",
      y: 10,
      color: "red"
    },
  ];

  const foodDomain = babyWeightData.map(({ date }) => date);

  return { foodDomain, foodSeries };
};

function FoodHoursContent() {
  const [babyActions, setBabyActions] = useState<BabyActionInterface[]>([]);
  const [babyFoodDomain, setBabyFoodDomain] = useState<any>([]);
  const [babyFoodSeries, setBabyFoodSeries] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    const getDdbItems = async () => {
      try {
        const items = await scanDynamoDBWithPagination();
        setBabyActions(items as BabyActionInterface[]);
        setLoading(false)
      } catch (error) {
        console.error(error);
        setLoading(false)
      }
    };

    // Fetch the data when the component mounts
    getDdbItems();
  }, []); // Runs only once on mount

  useEffect(() => {
    setLoading(true)
    if (babyActions.length > 0) {
      const { foodDomain, foodSeries } = getFoodData(babyActions);
      setBabyFoodDomain(foodDomain);
      setBabyFoodSeries(foodSeries);
    }
    setLoading(false)
  }, [babyActions]); // Runs whenever `babyActions` state changes

  return (
    <BarChart
      {...commonChartProps}
      statusType={loading ? "loading" : "finished"}
      fitHeight={true}
      height={25}
      yDomain={[0, 10]}
      xDomain={babyFoodDomain}
      xScaleType="categorical"
      stackedBars={true}
      hideFilter={true}
      series={babyFoodSeries}
      xTitle="Date"
      yTitle="Food count"
      ariaLabel="Feeding hours (day)"
      ariaDescription={`Count of Lily food times per day. ${barChartInstructions}`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: "Filter displayed food type",
        filterPlaceholder: "Filter food type",
        xTickFormatter: dateFormatter,
      }}
      detailPopoverSeriesContent={({ series, y }) => ({
        key: series.title,
        value: (
          <Link
            external={true}
            href="#"
            ariaLabel={`See details for ${y} hours on ${series.title} (opens in a new tab)`}
          >
            {y}
          </Link>
        ),
      })}
    />
  );
}
