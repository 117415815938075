import { ContentLayout, Grid, Header } from "@cloudscape-design/components";
import React, { FC } from "react";
import { WeightWidget } from "./dashboard/Weight";
import { BaseStaticWidget } from "./dashboard/base-static-widget";
import { FoodWidget } from "./dashboard/Food";
import { FoodCountWidget } from "./dashboard/FoodCount";
import { BabyOverviewWidget } from "./common/OverviewStats";
import { DiaperChangeWidget } from "./dashboard/DiaperChangeWidget";

interface DashboardProps {
  user: String;
}

export const Dashboard: FC<DashboardProps> = (props) => {
  return (
    <ContentLayout
      header={
        <Header variant="h2">Hello {`${props.user.split(" ")[0]}`} 👋</Header>
      }
    >
      <Grid
        gridDefinition={[
          { colspan: { l: 8, m: 8, default: 12 } },
          { colspan: { l: 6, m: 6, default: 12 } },
          { colspan: { l: 6, m: 6, default: 12 } },
          { colspan: { l: 6, m: 6, default: 12 } },
          { colspan: { l: 6, m: 6, default: 12 } },
        ]}
      >
        {[
          BabyOverviewWidget,
          WeightWidget,
          DiaperChangeWidget,
          FoodWidget,
          FoodCountWidget,
        ].map((widget, index) => (
          <BaseStaticWidget key={index} config={widget.data} />
        ))}
      </Grid>
    </ContentLayout>
  );
};
