import React, { FC, useState, useEffect } from "react";
import { AppLayout, SideNavigation } from "@cloudscape-design/components";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Auth from "@aws-amplify/auth";

import { List } from "./componenets/List";
import { Create } from "./componenets/Create";
import { Login } from "./componenets/Login";
import { awsConfig } from "./aws-config";
import { Hub } from "@aws-amplify/core";
import { Dashboard } from "./componenets/Dashboard";

interface AppProps {
  title: string;
}

const LOCALE = "en";
Auth.configure(awsConfig);

const App: FC<AppProps> = ({ title }) => {
  const [navigationOpen, setNavigationOpen] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<any>(null);

  useEffect(() => {
    const updateUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setAuthenticatedUser(user);
      } catch {
        setAuthenticatedUser(null);
      }
    };

    Hub.listen("auth", () => {
      updateUser();
    });

    updateUser();
  }, []);

  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <AppLayout
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => {
          setNavigationOpen(detail.open);
        }}
        navigation={
          <SideNavigation
            header={{
              href: "/",
              text: "Lily progress tracker",
            }}
            items={[
              { type: "link", text: `List`, href: `/` },
              { type: "link", text: `Create`, href: `/create` },
              { type: "link", text: `Dashboard`, href: `/dashboard` },
            ]}
          />
        }
        content={
          authenticatedUser ? (
            <Router>
              <Routes>
                <Route
                  path="/"
                  element={<List user={authenticatedUser.attributes.name} />}
                />
                <Route
                  path="create"
                  element={<Create user={authenticatedUser.attributes.name} />}
                />
                <Route
                  path="dashboard"
                  element={
                    <Dashboard user={authenticatedUser.attributes.name} />
                  }
                />
                <Route path="login" element={<Login />} />
              </Routes>
            </Router>
          ) : (
            <Login />
          )
        }
      />
    </I18nProvider>
  );
};

export default App;
