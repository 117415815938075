export enum BabyCareAction {
  Feeding = "Feeding",
  DiaperChange = "Diaper Change",
  Sleep = "Sleep",
  BathTime = "Bath Time",
  VITAMINE_D = "Vitamine D",
  VITAMINE_K = "Vitamine K",
  WEIGHT = "Weight"
}

export const APP = {
  SET: {
    // user
    INITIALIZE_USER: "SET_APP_INITIALIZE_USER",
    USER_SIGN_IN: "SET_APP_USER_SIGN_IN",
    USER_SIGN_OUT: "SET_APP_USER_SIGN_OUT",
    USER_ERROR: "SET_APP_USER_ERROR",
  },
}
