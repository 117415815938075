import React, { FC, useEffect, useState } from "react";
import Header from "@cloudscape-design/components/header";
import {
  Link,
  KeyValuePairs,
  StatusIndicator,
} from "@cloudscape-design/components";
import { BabyActionInterface, WidgetConfig } from "../../interfaces";
import { scanDynamoDBWithPagination } from "../../services/ddb";
import { transformDataForFoodCount, transformForFoodData } from "../../utils";

const MAX_BABY_FEEDING: number = 600;

const BabyOverviewWidgetContent: FC = () => {
  const [babyActions, setBabyActions] = useState<BabyActionInterface[]>([]);
  const [feedingCount, setFeedingCount] = useState<number>(0);
  const [feedingTotal, setFeedingTotal] = useState<number>(0);
  const [missingFeeding, setMissingFeeding] = useState<number>(
    feedingTotal - MAX_BABY_FEEDING
  );
  useEffect(() => {
    const getDdbItems = async () => {
      try {
        const items = await scanDynamoDBWithPagination();
        setBabyActions(items as BabyActionInterface[]);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch the data when the component mounts
    getDdbItems();
  }, []); // Runs only once on mount

  useEffect(() => {
    if (babyActions.length > 0) {
      const data = transformDataForFoodCount(babyActions);
      const feedingData = transformForFoodData(babyActions);
      const finalFeedingData = feedingData[feedingData.length - 1];
      setFeedingCount(data[data.length - 1].feedingCount);
      setFeedingTotal(
        // @ts-ignore
        finalFeedingData!.artificialMilkQuantity +
          // @ts-ignore
          finalFeedingData!.maternalMilkQuantity
      );
      setMissingFeeding(MAX_BABY_FEEDING - feedingTotal);
    }
  }, [babyActions]);
  return (
    <KeyValuePairs
      columns={3}
      items={[
        {
          label: "📈 Feeding status",
          title: "test",
          value: (
            <>
              <Link variant="awsui-value-large" href="#">
                {missingFeeding > 0 && (
                  <StatusIndicator type="success">
                    Normal consumption
                  </StatusIndicator>
                )}
                {missingFeeding < 0 && (
                  <StatusIndicator type="error">
                    Abnormal consumption, Lily consumed an extra of{" "}
                    {Math.abs(missingFeeding)}
                  </StatusIndicator>
                )}
              </Link>
            </>
          ),
        },
        {
          label: "🍼 Feeding count",
          title: "test",
          value: (
            <>
              <Link variant="awsui-value-large" href="#">
                {feedingCount ?? 0}
              </Link>
            </>
          ),
        },
        {
          label: "🍼 Feeding count",
          title: "test",
          value: (
            <>
              <Link variant="awsui-value-large" href="#">
                {feedingTotal ?? 0}
              </Link>{" "}
              <i>ml</i>
            </>
          ),
        },
      ]}
    />
  );
};

const BabyOverviewHeader: FC = () => {
  return <Header variant="h2">Today's Lily stats</Header>;
};

export const BabyOverviewWidget: WidgetConfig = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 3 },
  data: {
    title: "Today's baby stats",
    description: "Overview today's stats",
    header: BabyOverviewHeader,
    content: BabyOverviewWidgetContent,
  },
};
