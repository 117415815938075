import { BabyCareAction } from "./constants";
import { BabyActionInterface } from "./interfaces";

export const getTodayDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
  const day = String(today.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const toISOFormat = (date: string, time: string): string => {
  // Combine date and time into a single string
  const combined = `${date}T${time}:00`;

  // Convert to ISO format
  const isoString = new Date(combined).toISOString();

  return isoString;
};

export const isoToTimestamp = (isoString: string): number => {
  const date = new Date(isoString);
  return Math.floor(date.getTime() / 1000);
};

export const transformData = (
  data: BabyActionInterface[]
): {
  date: Date;
  weight?: number;
}[] => {
  return data
    .filter((item: BabyActionInterface) => Number(item.weight) > 0)
    .map((record) => {
      return {
        date: new Date(record.time),
        weight: record.weight,
      };
    })
    .sort((a, b) => a.date.getTime() - b.date.getTime()); // Sort by date
};

export const transformForFoodData = (
  data: BabyActionInterface[]
): {
  date: Date;
  artificialMilkQuantity?: number;
  maternalMilkQuantity?: number;
  diaperChangeCount?: number;
}[] => {
  return Object.values(
    data.reduce((acc, record) => {
      const dateKey = new Date(record.time).toISOString().split("T")[0]; // Group by day (YYYY-MM-DD)

      if (!acc[dateKey]) {
        acc[dateKey] = {
          date: new Date(dateKey),
          artificialMilkQuantity: 0,
          maternalMilkQuantity: 0,
        };
      }

      if (record.action === BabyCareAction.Feeding) {
        acc[dateKey].artificialMilkQuantity! +=
          record.artificialMilkQuantity || 0;
        acc[dateKey].maternalMilkQuantity! += record.maternalMilkQuantity || 0;
      }

      return acc;
    }, {} as { [key: string]: { date: Date; artificialMilkQuantity: number; maternalMilkQuantity: number } })
  ).sort((a, b) => a.date.getTime() - b.date.getTime()); // Sort by date
};

export const transformDataForFoodCount = (
  data: BabyActionInterface[]
): {
  date: Date;
  feedingCount: number;
}[] => {
  return Object.values(
    data.reduce(
      (acc, record) => {
        const dateKey = new Date(record.time).toISOString().split("T")[0]; // Group by day (YYYY-MM-DD)

        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: new Date(dateKey),
            feedingCount: 0, // Initialize feeding count for this date
          };
        }

        // If food was given, increment the feeding count
        if (
          record.action === BabyCareAction.Feeding &&
          (record.artificialMilkQuantity || record.maternalMilkQuantity)
        ) {
          acc[dateKey].feedingCount += 1;
        }

        return acc;
      },
      {} as {
        [key: string]: {
          date: Date;
          feedingCount: number;
        };
      }
    )
  ).sort((a, b) => a.date.getTime() - b.date.getTime()); // Sort by date
};

export const formatDateString = (dateString: string): string => {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  // Return formatted string
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export const transformDataForDiaperCount = (
  data: BabyActionInterface[]
): {
  date: Date;
  diaperChangeCount: number;
}[] => {
  return Object.values(
    data.reduce(
      (acc, record) => {
        const dateKey = new Date(record.time).toISOString().split("T")[0]; // Group by day (YYYY-MM-DD)

        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: new Date(dateKey),
            diaperChangeCount: 0, // Initialize feeding count for this date
          };
        }

        // If food was given, increment the feeding count
        if (record.action === BabyCareAction.DiaperChange) {
          const diaperActionsRecorded = record
            .diaperType!.split(",")
            .map((item) => item.trim());
          if (diaperActionsRecorded.includes("Caca 💩")) {
            acc[dateKey].diaperChangeCount += 1;
          }
        }

        return acc;
      },
      {} as {
        [key: string]: {
          date: Date;
          diaperChangeCount: number;
        };
      }
    )
  ).sort((a, b) => a.date.getTime() - b.date.getTime()); // Sort by date
};
