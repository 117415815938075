import React, { useState } from "react";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Auth from "@aws-amplify/auth";

export const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [error, setError] = useState<string | null>(null);

  const signIn = async () => {
    try {
      await Auth.signIn(username, password);
      window.location.href = "/"
    } catch (error: any) {
      // setError(error.message);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={signIn}>
              Login
            </Button>
          </SpaceBetween>
        }
        header={<Header variant="h1">Login</Header>}
      >
        <Container>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Email">
              <Input
                value={username}
                placeholder="Enter your email"
                type="email"
                onChange={({ detail }) => setUsername(detail.value)}
              />
            </FormField>
            <FormField label="password">
              <Input
                value={password}
                placeholder="Enter your password"
                type="password"
                onChange={({ detail }) => setPassword(detail.value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>
      </Form>
    </form>
  );
};
