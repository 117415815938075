// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React, { useEffect, useState } from "react";
import {
  Header,
  LineChart,
  LineChartProps,
  Link,
} from "@cloudscape-design/components";
import {
  commonChartProps,
  dateTimeFormatter,
  lineChartInstructions,
} from "../widgets/common-charts";
import { BabyActionInterface, WidgetConfig } from "../../interfaces";
import { scanDynamoDBWithPagination } from "../../services/ddb";
import { transformData } from "../../utils";

export const WeightWidget: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    title: "Weight progress",
    description: "Progress track for Lily's weight in kgs",
    header: BabyWeightHeader,
    content: BabyWeightContent,
    staticMinHeight: 560,
  },
};

function BabyWeightHeader() {
  return (
    <Header variant="h2" description="Progress track for Lily's weight in kgs">
      Lily's weight progress
    </Header>
  );
}

const GetBabyData = (data: BabyActionInterface[]) => {
  const babyWeightData = transformData(data);

  const babyWeightDomain = [
    babyWeightData[0].date,
    babyWeightData[babyWeightData.length - 1].date,
  ];
  const babyWeightSeries: LineChartProps<Date>["series"] = [
    {
      title: "weight",
      type: "line",
      valueFormatter: (value) => value.toLocaleString("en-US"),
      data: babyWeightData.map((datum) => ({
        x: datum.date,
        y: Number(datum["weight"]),
      })),
    },
  ];

  return { babyWeightDomain, babyWeightSeries };
};

export default function BabyWeightContent() {
  const [babyActions, setBabyActions] = useState<BabyActionInterface[]>([]);
  const [babyWeightDomain, setBabyWeightDomain] = useState<any>([]);
  const [babyWeightSeries, setBabyWeightSeries] = useState<any>([]);

  useEffect(() => {
    const getDdbItems = async () => {
      try {
        const items = await scanDynamoDBWithPagination();
        setBabyActions(items as BabyActionInterface[]);
      } catch (error) {
        console.error(error);
      }
    };

    // Fetch the data when the component mounts
    getDdbItems();
  }, []); // Runs only once on mount

  useEffect(() => {
    if (babyActions.length > 0) {
      const { babyWeightDomain, babyWeightSeries } = GetBabyData(babyActions);
      setBabyWeightDomain(babyWeightDomain);
      setBabyWeightSeries(babyWeightSeries);
    }
  }, [babyActions]); // Runs whenever `babyActions` state changes

  return (
    <LineChart
      {...commonChartProps}
      hideFilter={true}
      fitHeight={true}
      height={50}
      series={babyWeightSeries}
      yDomain={[0, 5]}
      xDomain={babyWeightDomain}
      xScaleType="time"
      xTitle="Time (Paris time)"
      yTitle="Weight (Kgs)"
      ariaLabel="Weight (Kgs)"
      ariaDescription={`Line chart showing baby weight progress in. ${lineChartInstructions}`}
      i18nStrings={{
        ...commonChartProps.i18nStrings,
        filterLabel: "Filter displayed instances",
        filterPlaceholder: "Filter instances",
        xTickFormatter: dateTimeFormatter,
      }}
      detailPopoverSeriesContent={({ series, y }) => ({
        key: (
          <Link external={true} href="#">
            {series.title}
          </Link>
        ),
        value: y,
      })}
    />
  );
}
