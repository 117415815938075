import DynamoDB from "aws-sdk/clients/dynamodb";
import Auth from "@aws-amplify/auth";
import { BabyActionInterface } from "../interfaces";

const getDocumentClient = (): Promise<DynamoDB.DocumentClient> => {
  return Auth.currentCredentials().then(
    (credentials) =>
      new DynamoDB.DocumentClient({
        region: process.env.REACT_APP_REGION,
        credentials: Auth.essentialCredentials(credentials),
      })
  );
};

export const putItemToDynamo = async (Item: any) => {
  const params: DynamoDB.DocumentClient.PutItemInput = {
    TableName: `${process.env.REACT_APP_USER_TABLE}`,
    Item,
  };

  const client = await getDocumentClient();
  return await client.put(params).promise();
};

export const getItemFromDynamo = async (Key: any) => {
  const params: DynamoDB.DocumentClient.GetItemInput = {
    TableName: `${process.env.REACT_APP_USER_TABLE}`,
    Key,
  };

  const client = await getDocumentClient();
  return await client.get(params).promise();
};

export const scanDynamoDBWithPagination = async (
  pageSize?: number
): Promise<DynamoDB.DocumentClient.ItemList> => {
  const client = await getDocumentClient();
  let items: DynamoDB.DocumentClient.ItemList = [];
  let lastEvaluatedKey: DynamoDB.DocumentClient.Key | undefined = undefined;

  do {
    // Update the scanParams with the lastEvaluatedKey and Limit for pagination
    const params: DynamoDB.DocumentClient.ScanInput = {
      TableName: `${process.env.REACT_APP_USER_TABLE}`,
      ExclusiveStartKey: lastEvaluatedKey,
      Limit: pageSize ? pageSize : 100,
    };

    // Perform the scan
    const data = await client.scan(params).promise();

    // Append the retrieved items to the list
    if (data.Items) {
      items = items.concat(data.Items);
    }

    // Update lastEvaluatedKey to continue pagination if there are more items
    lastEvaluatedKey = data.LastEvaluatedKey;
  } while (lastEvaluatedKey); // Continue if there are more items to fetch

  return items as BabyActionInterface[];
};

export const deleteItemFromDynamo = async (Key: any) => {
  const params: DynamoDB.DocumentClient.DeleteItemInput = {
    TableName: `${process.env.REACT_APP_USER_TABLE}`,
    Key,
  };

  const client = await getDocumentClient();
  return await client.delete(params).promise();
};

export const updateItemInDynamo = async (
  Key: DynamoDB.DocumentClient.Key,
  updateData: any
) => {
  const client = await getDocumentClient();

  // Construct the update expression and attribute values
  const UpdateExpression =
    "SET " +
    Object.keys(updateData)
      .map((key, index) => `#attr${index} = :val${index}`)
      .join(", ");
  const ExpressionAttributeNames = Object.keys(updateData).reduce(
    (acc, key, index) => {
      acc[`#attr${index}`] = key;
      return acc;
    },
    {} as DynamoDB.DocumentClient.ExpressionAttributeNameMap
  );
  const ExpressionAttributeValues = Object.keys(updateData).reduce(
    (acc, key, index) => {
      acc[`:val${index}`] = updateData[key];
      return acc;
    },
    {} as DynamoDB.DocumentClient.ExpressionAttributeValueMap
  );

  const params: DynamoDB.DocumentClient.UpdateItemInput = {
    TableName: `${process.env.REACT_APP_USER_TABLE}`,
    Key,
    UpdateExpression,
    ExpressionAttributeNames,
    ExpressionAttributeValues,
    ReturnValues: "UPDATED_NEW", // Return the updated attributes
  };

  return await client.update(params).promise();
};
