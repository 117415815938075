import React from "react";
import { Container } from "@cloudscape-design/components";
import { WidgetDataType } from "../../interfaces";

export const BaseStaticWidget = ({ config }: { config: WidgetDataType }) => {
  const Wrapper = config.provider ?? React.Fragment;
  return (
    <div style={{ minHeight: config.staticMinHeight, height: 150 }}>
      <Wrapper>
        <Container
          header={<config.header />}
          fitHeight={true}
          footer={config.footer && <config.footer />}
          disableContentPaddings={config.disableContentPaddings}
        >
          <config.content />
        </Container>
      </Wrapper>
    </div>
  );
};
