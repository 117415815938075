import React, { FC, useState } from "react";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Select from "@cloudscape-design/components/select";
import DatePicker from "@cloudscape-design/components/date-picker";
import TimeInput from "@cloudscape-design/components/time-input";
import Input from "@cloudscape-design/components/input";
import Multiselect from "@cloudscape-design/components/multiselect";
import { v4 as uuidv4 } from "uuid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { BabyCareAction } from "../constants";
import { getTodayDate } from "../utils";
import { putItemToDynamo } from "../services/ddb";

interface CreateProps {
  user: string;
}

export const Create: FC<CreateProps> = (props) => {
  const availableOptions: Array<any> = Object.values(BabyCareAction).map(
    (value, idx) => {
      return { label: value, value: idx.toString() };
    }
  );
  const diaperOptions = [
    {
      label: "Caca 💩",
      value: "2",
      description: "",
    },
    {
      label: "Pipi",
      value: "1",
      description: "",
    },
  ];
  const todaysDate = getTodayDate();
  const [selectedOption, setSelectedOption] = useState(availableOptions[0]);
  const [selectedDiaperOptions, setSelectedDiaperOptions] = useState<any>([
    {
      label: "Pipi",
      value: "1",
      description: "",
    },
  ]);
  const [dateValue, setDateValue] = useState(todaysDate);
  const [timeValue, settimeValue] = useState("00:00");
  const [maternalQuantity, setMaternalQuantity] = useState(0);
  const [artificialQuantity, setArtificialQuantity] = useState(0);
  const [weight, setWeight] = useState("0");
  const [loading, setLoading] = useState(false);

  const notifySuccess = () =>
    toast.success("🎉 🎊 Successfully added item", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const notifyError = () =>
    toast.error("😭 Something went wrong, contact Anis!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  const handleCreateItem = () => {
    setLoading(true);
    try {
      putItemToDynamo({
        id: uuidv4(),
        time: `${dateValue}T${timeValue}:00.000Z`,
        action: selectedOption.label,
        maternalMilkQuantity: maternalQuantity,
        artificialMilkQuantity: artificialQuantity,
        diaperType:
          selectedOption.label === BabyCareAction.DiaperChange
            ? selectedDiaperOptions.map((item: any) => item.label).join(", ")
            : "",
        parent: props.user.split(" ")[0],
        weight: parseFloat(weight),
      }).then((_) => {
        setLoading(false);
        notifySuccess();
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
      notifyError();
    }
  };
  return (
    <>
      <form onSubmit={(e) => e.preventDefault()}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button formAction="none" variant="link">
                Cancel
              </Button>
              <Button
                variant="primary"
                formAction="submit"
                onClick={handleCreateItem}
                loading={loading}
              >
                Create{" "}
              </Button>
            </SpaceBetween>
          }
          header={
            <Header variant="h2">
              {" "}
              Hello {`${props.user.split(" ")[0]}`} 👋
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="xl">
            <Container header={<Header variant="h2">Add baby action</Header>}>
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Select baby action">
                  <Select
                    selectedOption={selectedOption}
                    onChange={({ detail }) =>
                      setSelectedOption(detail.selectedOption)
                    }
                    options={availableOptions}
                  />
                </FormField>

                {selectedOption.label === BabyCareAction.Feeding && (
                  <>
                    <FormField label="🤱 Maternal milk quantity (ml)">
                      <Input
                        onChange={({ detail }) =>
                          setMaternalQuantity(Number(detail.value))
                        }
                        value={maternalQuantity.toString()}
                        inputMode="numeric"
                      />
                    </FormField>

                    <FormField label="🍼 Artificial milk quantity (ml)">
                      <Input
                        onChange={({ detail }) =>
                          setArtificialQuantity(Number(detail.value))
                        }
                        value={artificialQuantity.toString()}
                        inputMode="numeric"
                      />
                    </FormField>
                  </>
                )}

                {selectedOption.label === BabyCareAction.WEIGHT && (
                  <FormField label="🐣 Weight (Kgs)">
                    <Input
                      onChange={({ detail }) => {
                        // @ts-ignore
                        setWeight(detail.value);
                      }}
                      value={weight.toString()}
                      inputMode="text"
                    />
                  </FormField>
                )}

                {selectedOption.label === BabyCareAction.DiaperChange && (
                  <FormField label="💩 Select diaper content">
                    <Multiselect
                      selectedOptions={selectedDiaperOptions}
                      options={diaperOptions}
                      onChange={({ detail }) =>
                        setSelectedDiaperOptions(detail.selectedOptions)
                      }
                      placeholder="Choose options"
                    />
                  </FormField>
                )}

                <FormField label="🕓 Time">
                  <SpaceBetween direction="horizontal" size="xs">
                    <DatePicker
                      onChange={({ detail }) => setDateValue(detail.value)}
                      value={dateValue}
                      openCalendarAriaLabel={(selectedDate) =>
                        "Choose certificate expiry date" +
                        (selectedDate
                          ? `, selected date is ${selectedDate}`
                          : "")
                      }
                      placeholder="YYYY/MM/DD"
                    />
                    <TimeInput
                      onChange={({ detail }) => settimeValue(detail.value)}
                      value={timeValue}
                      format="hh:mm"
                      placeholder="hh:mm"
                    />
                  </SpaceBetween>
                </FormField>
              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Form>
      </form>
      <ToastContainer />
    </>
  );
};
